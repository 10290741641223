import React, { useImperativeHandle, forwardRef }  from 'react';
import { API, Auth } from 'aws-amplify';

import s from './ExportPane.module.css';
import cs from 'clsx';
import ExportPaneHeader from './ExportPaneHeader/ExportPaneHeader';
import { getSelectedConcept } from '../utils/conceptUtils';
import { SupportedLanguagesMenu } from './SupportedLanguagesMenu/SupportedLanguagesMenu';
import { Flex, Heading, Spinner } from '@chakra-ui/react';
import displayNames from '../displayNames';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf/dist/jspdf.umd.min.js';

const getFormattedCategorySummary = (category, filteredResults) => {
  const isMedicalCondition = category === 'MEDICAL_CONDITION';
  const isMedication = category === 'MEDICATION';
  const isDiagnosis = category === 'DIAGNOSIS';
  
  if (isMedicalCondition || isMedication || isDiagnosis) {
    //const conceptProperty = isMedicalCondition ? 'ICD10CMConcepts' : 'RxNormConcepts';
    const conceptProperty = isMedicalCondition ? 'SNOMEDCTConcepts' : (isMedication ? 'DDCNormConcepts':(isDiagnosis ? 'ICD10CMConcepts':'RxNormConcepts'));
    
    return filteredResults
      .map((result) => {
        const text = result.Text;

        const concepts = result[conceptProperty];

        if (!concepts) return text;
        const selectedConcept = getSelectedConcept(result);
	if(selectedConcept){
        return `${text}|${selectedConcept.Code}|${selectedConcept.Description}`;
	}else{
		return `${text}`;
	}
	})
      .join('\n');
     
  }

  return filteredResults.length > 0
    ? filteredResults
        .map(({ Text, Attributes }) => `${Text}${Attributes?.map((key) => `|${key.Text}`).join('') ?? ''}`)
        .join('\n')
    : 'N/A';
};

const SUMMARY_CATEGORIES = ['DIAGNOSIS','MEDICATION', 'ANATOMY', 'MEDICAL_CONDITION', 'TEST_TREATMENT_PROCEDURE'];
const SUMMARY_CATEGORIES_SET = new Set(SUMMARY_CATEGORIES);

const getFormattedCategorySummaries = (results) => {
  const resultsByCategory = {};

  SUMMARY_CATEGORIES.forEach((category) => {
    resultsByCategory[category] = [];
  });

  results.forEach((result) => {
    const category = result.Category;

    if (SUMMARY_CATEGORIES_SET.has(category)) resultsByCategory[category].push(result);
  });

  return SUMMARY_CATEGORIES.map((category) => ({
    category,
    summary: getFormattedCategorySummary(category, resultsByCategory[category]),
  }));
};

const getFilteredResults = (resultChunks, excludedItems) =>
  [].concat(...resultChunks).filter((x) => !excludedItems.includes(x.id));

const getExportSections = ({ resultChunks, excludedItems, soapSummary, transcriptChunks }) => {
  const filteredResults = getFilteredResults(resultChunks, excludedItems);

  const formattedCategorySummaries = getFormattedCategorySummaries(filteredResults);

  return [
    {
      header: {
        type: 'SECTION',
        content: 'Summary',
      },
      content: [
        `Thank you for visiting the clinic today, ${new Date()
          .toISOString()
          .slice(
            0,
            10,
          )}. Please take a moment to review the following important information from today's consultation and reach out to us at +12345678910 if you have any questions.`,
        soapSummary,
      ],
    },
    ...formattedCategorySummaries.map(({ category, summary }) => ({
      header: {
        type: 'SUB_SECTION',
        content: displayNames[category],
      },
      content: [summary],
    })),
    {
      header: {
        type: 'SECTION',
        content: 'Visit Transcription',
      },
      content: [
        'Below is the transcription for your visit',
        ...(transcriptChunks ? transcriptChunks : []).map(({ speaker, text }) =>
          speaker ? `${speaker}\n${text}` : text,
        ),
      ],
    },
  ];
};

const SPACE_CHARACTER_CODE = 32;

// https://stackoverflow.com/questions/57068850/how-to-split-a-string-into-chunks-of-a-particular-byte-size
const chunkByByteLimit = (s, limit) => {
  const result = [];

  const decoder = new TextDecoder('utf-8');
  let buf = new TextEncoder('utf-8').encode(s);

  while (buf.length) {
    let i = buf.lastIndexOf(SPACE_CHARACTER_CODE, limit + 1);
    // If no space found, try forward search
    if (i < 0) i = buf.indexOf(SPACE_CHARACTER_CODE, limit);
    // If there's no space at all, take all
    if (i < 0) i = buf.length;
    // This is a safe cut-off point; never half-way a multi-byte
    result.push(decoder.decode(buf.slice(0, i)));
    buf = buf.slice(i + 1); // Skip space (if any)
  }

  return result;
};

const MAX_TRANSLATION_BYTES = 5000;

const translateSection = (section, lang, jwtToken) => {
  const translationPromises = [section.header.content, ...section.content].map((text) => {
    if (text.trim() === '') return Promise.resolve(text);

    // break into chunks by Amazon Translate byte limit and combine responses
    const chunks = chunkByByteLimit(text, MAX_TRANSLATION_BYTES);

    const translatedChunkPromises = chunks.map((chunk) =>
      API.get('MTADemoAPI', 'getTranscriptionTranslation', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        response: true,
        queryStringParameters: {
          TargetLanguageCode: lang,
          TranslationSourceText: chunk,
        },
      }).then((response) => response.data.translate.TranslatedText),
    );

    return Promise.all(translatedChunkPromises).then((translatedChunks) => translatedChunks.join(' '));
  });

  return Promise.all(translationPromises).then(([headerContent, ...content]) => ({
    header: {
      ...section.header,
      content: headerContent,
    },
    content,
  }));
};

const translateSections = (sections, lang, jwtToken) => {
  return Promise.all(sections.map((section) => translateSection(section, lang, jwtToken)));
};

var session_id='';
const ExportPane = forwardRef(({ transcriptChunks, resultChunks, visible, excludedItems, soapSummary }, ref) =>{
//export default function ExportPane({ transcriptChunks, resultChunks, visible, excludedItems, soapSummary }) {
	const [sections, setSections] = React.useState(() =>
    getExportSections({ excludedItems, resultChunks, soapSummary, transcriptChunks }),
  );
  const [lang, setLang] = React.useState('en');
  const [isLoadingTranslation, setIsLoadingTranslation] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  session_id = queryParams.get("session_id");
  //console.log('session_id: '+session_id);

  React.useEffect(() => {
    if (!visible) return;

    const englishSections = getExportSections({ excludedItems, resultChunks, soapSummary, transcriptChunks });

    if (lang === 'en') {
      setSections(englishSections);
      return;
    }

    setIsLoadingTranslation(true);

    Auth.currentSession()
      .then((session) => session.getIdToken().getJwtToken())
      .then((token) => translateSections(englishSections, lang, token))
      .then(setSections)
      .finally(() => setIsLoadingTranslation(false));
  }, [visible, excludedItems, resultChunks, soapSummary, transcriptChunks, lang]);


// Expose the exportData function to the parent component via ref
    useImperativeHandle(ref, () => ({
        triggerExport: () => {
            //console.log("Calling postToAster");
		//postToAster([sections, soapSummary, resultChunks]);
        }
    }));

return (
    <div className={cs(s.base, visible && s.visible)}>
      <div className={s.pageContainer}>
        <Flex alignItems='center' p={4}>
          <Heading as='div' size='md' mr={4}>
            Translate Summary
          </Heading>
          <SupportedLanguagesMenu currentLang={lang} onLangClick={setLang} disabled={isLoadingTranslation} />
        </Flex>
                <div id="pdfReport">
        <div className={s.page}>
          {isLoadingTranslation ? (
            <Flex width='100%' height='100%' justifyContent='center' alignItems='center'>
              <Spinner size='xl' />
            </Flex>
          ) : (
            <>
              <header>
                <div className={s.logo} />
              </header>

              <main>
                {sections.map(({ header, content }) => (
                  <React.Fragment key={header.content}>
                    <ExportPaneHeader content={header.content} type={header.type} />
                    {content.map((paragraph, i) => (
                      <p key={i}>{paragraph}</p>
                    ))}
                  </React.Fragment>
                ))}
              </main>
            </>
          )}
        </div>
      </div>
      </div>
      <div className={s.actions}>
        <button
          className={s.pdf}
          onClick={() =>postToAster([sections, soapSummary, resultChunks])
 /*           alert(
              'You can take the transcription, detected entities, and generated summaries and integrate them into your EHR system, where you can continue editing or commit it to the patient record.',
            )*/
          }
        >
          Export to EHR system
        </button>
                <div style={{display:'none'}}>
        <button className={s.pdf} onClick={() => window.print()}>
          Export to PDF
        </button>
                </div>
      </div>
    </div>
  );
}
);

export default ExportPane;
  //async function postToAster(body) {
const postToAster = async(body) => {	
	console.log(JSON.stringify(body));
	var token=await generateToken();
	//console.log('token: '+token);
	/*
	const canvas = await html2canvas(this);
	// Create PDF using jsPDF
    const pdf = new jsPDF();
	const pdfBlob = pdf.output('blob');
	
	//var file=await ReactPDF.renderToStream(<Document><ExportPane/></Document>);
	let myPdf = <BlobProvider document={()=> <Document><ExportPane/></Document> }/>;
	console.log('myPdf: '+JSON.stringify(myPdf));
    var file = new File([myPdf], 'filename.pdf', { type: 'application/pdf'});
	console.log('file: '+JSON.stringify(file));
	*/
	
	/*
	const doc = <Document><Page size="A4"><View></View></Page></Document>;
	const blobPdf = await pdf(doc);
	blobPdf.updateContainer(doc);
	var file = await blobPdf.toBlob();
	*/
	
// Target the part of the page you want to capture
    const page = document.getElementById("pdfReport");
	//console.log("Page: "+page);
	var reportFile = session_id+'_report.pdf';

    // Use html2canvas to convert the page to a canvas
    html2canvas(page, { scale: 2 }).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a new jsPDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Calculate the image dimensions to fit it in the PDF
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
	  let heightLeft = imgHeight;
      let position = 0;

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
	  heightLeft -= pageHeight;
	  
      // Add more pages if the content overflows
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // move to next page's start
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }	  
	  
      // Generate the PDF as a Blob
      const pdfBlob = pdf.output('blob');

      // You can now handle the blob as needed, for example:
      // - Download the blob
	  /*
      const blobUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = reportFile;
      link.click();
	  */
	  
      // - Or upload it to a server
      // const formData = new FormData();
      // formData.append('file', pdfBlob, 'page.pdf');
      // uploadToServer(formData);
	  
		var formData = new FormData();
		formData.append("session_id", session_id);
		formData.append("payload", JSON.stringify(body));
		formData.append('summary_report', pdfBlob, reportFile);

		var submitResp=await invokeAsterAPI(token, formData);
		console.log(JSON.stringify(submitResp));	  
    });  
	
	alert('Exported to EHR. Thanks!');
	
}

async function generateToken() {
	var url='https://devapi.alif.onl/api/v1/generate_token';
	var payload={
		"user_id": "info@pragya.ai",
		"pin": "$2a$12$V4lA8omsr0e.DUDWsxFzqOr9quWw0kyWOOC2NV8XAIL.JzC0GwwxW"
	};
	var options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
	//console.log('options: '+JSON.stringify(options));
	var returnToken='';
	try{
		var response=await fetch(url, options);
		var data = await response.json();
		//console.log(JSON.stringify(data));
		returnToken=data['data']['token'];
	}catch(e) {
		console.log('error generating token: '+e);
	}
	return '28baafd76fae6dbee33d91cce447f7ee';//returnToken;
}

async function invokeAsterAPI(token, formdata) {

	var url='https://devapi.alif.onl/api/v1/med_save_data';
	var options = {
		  method: "POST",
		  body: formdata,
		  headers: {
			//'content-type': 'multipart/form-data',
			//"Content-Type": "application/json",
			"Authorization": "Bearer "+token,
		  },
    };
	//console.log('options: '+JSON.stringify(options));
	try{
		var response=await fetch(url, options);
		var data = await response.json();
		return data;
	}catch(e) {
		console.log('error submitting data: '+e);
	}

}
