import { Icon } from '@chakra-ui/react';

export const DeleteIcon = (props) => (
  <Icon viewBox='0 0 278.07 278.07' color='#545b64' boxSize='1.5em' {...props}>
    <path
      fill='currentColor'
      d='M203.55,75.78a9.14,9.14,0,0,0-12.93,0l-48.39,48.39L93.84,75.78A9.14,9.14,0,0,0,80.91,88.71L129.3,137.1,80.91,185.49a9.14,9.14,0,1,0,12.93,12.93L142.23,150l48.39,48.39a9.14,9.14,0,0,0,12.93-12.93L155.16,137.1l48.39-48.39A9.14,9.14,0,0,0,203.55,75.78Z'
    />
  </Icon>
);
