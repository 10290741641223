// alphabetically sorted except for english and spanish (mexico) for emphasis
export const ORDERED_SUPPORTED_TRANSLATION_CODES = [
  'en',
  'es-MX',
  'af',
  'sq',
  'am',
  'ar',
  'hy',
  'az',
  'bn',
  'bs',
  'bg',
  'ca',
  'zh',
  'zh-TW',
  'hr',
  'cs',
  'da',
  'fa-AF',
  'nl',
  'et',
  'fa',
  'fi',
  'fr',
  'fr-CA',
  'ka',
  'de',
  'el',
  'gu',
  'ht',
  'ha',
  'he',
  'hi',
  'hu',
  'is',
  'id',
  'it',
  'ja',
  'kn',
  'kk',
  'ko',
  'lv',
  'lt',
  'mk',
  'ms',
  'ml',
  'mt',
  'mn',
  'no',
  'ps',
  'pl',
  'pt',
  'ro',
  'ru',
  'sr',
  'si',
  'sk',
  'sl',
  'so',
  'es',
  'sw',
  'sv',
  'tl',
  'ta',
  'te',
  'th',
  'tr',
  'uk',
  'ur',
  'uz',
  'vi',
  'cy',
];

export const LANGUAGE_NAMES = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  az: 'Azerbaijani',
  bn: 'Bengali',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh: 'Chinese (Simplified)',
  'zh-TW': 'Chinese (Traditional)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  'fa-AF': 'Dari',
  nl: 'Dutch',
  en: 'English',
  et: 'Estonian',
  fa: 'Farsi (Persian)',
  fi: 'Finnish',
  fr: 'French',
  'fr-CA': 'French (Canada)',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  gu: 'Gujarati',
  ht: 'Haitian Creole',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  ko: 'Korean',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mk: 'Macedonian',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mn: 'Mongolian',
  no: 'Norwegian',
  ps: 'Pashto',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  es: 'Spanish',
  'es-MX': 'Spanish (Mexico)',
  sw: 'Swahili',
  sv: 'Swedish',
  tl: 'Tagalog',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  cy: 'Welsh',
};
