import React, { useState, useEffect } from 'react';
import { AppContext } from './libs/contextLib';
import { Auth } from 'aws-amplify';
import './App.css';
import Routes from './Routes';
import Login from './Login';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <div className='App'>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
	  {!isAuthenticated && <Login />}
          <Routes />
        </AppContext.Provider>
      </div>
    )
  );
}

export default App;
