import { ComprehendMedical } from 'aws-sdk';
import { Entity } from 'aws-sdk/clients/comprehendmedical';


const detectEntities = async (
  text: string | undefined,
  clientParams?: ComprehendMedical.Types.ClientConfiguration,
): Promise<Entity[]> => {
  
  const comprehendMedical = new ComprehendMedical(clientParams);

  if (text === undefined || text.replace(/\s/g, '') === '') return [];
  const entities = []  
  const resp = await comprehendMedical.detectEntitiesV2({ Text: text }).promise();
  // Loop through detected entities
        //resp.Entities.forEach((entity) => {
          //  if (entity.Category === 'MEDICAL_CONDITION') {
                // Check if the entity has the DIAGNOSIS trait
            //    const hasDiagnosisTrait = entity.Traits.some(trait => trait.Name === 'DIAGNOSIS');
              //  if (hasDiagnosisTrait) {
                    // If it's a diagnosis, add to diagnosisEntities list
		//    entity.Category = 'DIAGNOSIS';
                  //  entities.push(entity);
               // } else {
                    // Otherwise, add it to otherMedicalConditions list
                 //   entities.push(entity);
                //}
           // }
       // });
     return entities;
        };


// Function to get diagnosis entities
const diagnosticEntities = async function getDiagnosisEntities(entities) {
    try {

        // List to store diagnosis entities
        const diagnosisEntities = [];

        // Loop through all detected entities
        entities.forEach((entity) => {
            // Check if the entity is a MEDICAL_CONDITION
            if (entity.Category === 'MEDICAL_CONDITION') {
                // Check for traits and find the DIAGNOSIS trait
                const hasDiagnosisTrait = entity.Traits.some(trait => trait.Name === 'DIAGNOSIS');

                if (hasDiagnosisTrait) {
		    entity.Category = 'DIAGNOSIS';
                    diagnosisEntities.push(entity);
                }
            }
        });

        // Return the filtered diagnosis entities
        return diagnosisEntities;
    } catch (error) {
        console.error('Error detecting diagnostic entities:', error);
        throw error;
    }
}

export default detectEntities;
