import { ComprehendMedical } from 'aws-sdk';
import { ComprehendMedicalClient, InferSNOMEDCTCommand} from "@aws-sdk/client-comprehendmedical"; 
import {v4 as uuidv4} from 'uuid';

// Configuration for Azure OpenAI
const apiKey = '9fb5af1332a648b1bba4198731e389bd';
const azureEndpoint = 'https://pragyaaGPT4.openai.azure.com/';
const apiVersion = '2024-05-01-preview';

// Set up headers
const headers = {
    'Content-Type': 'application/json',
    'api-key': apiKey
};

const detectEntities = async (
  text: string | undefined,clientParams?: ComprehendMedical.Types.ClientConfiguration,
): Promise<[]> => {
	var assistantId='asst_26RhVbAwc49XU8NBialPjTYf';
	var norm='(DDC)';
	var normConcept='DDCNormConcepts';
	var category='MEDICATION';
	var type = "BRAND_NAME";
	
	var contentList=[];
	var entity=undefined;
  //console.log('text: ', text);
  const comprehendMedical = new ComprehendMedical(clientParams);
  
  if (text === undefined || text.replace(/\s/g, '') === '') return [];
  const resp = await comprehendMedical.inferRxNorm({ Text: text }).promise();
  var entities= resp.Entities;
  //console.log('entities: ',JSON.stringify(entities));
  if (entities.length === 0) {
	    const client = new ComprehendMedicalClient({region: clientParams.region,
		  credentials: {
			accessKeyId: clientParams.accessKeyId,
			secretAccessKey: clientParams.secretAccessKey,
			sessionToken: clientParams.sessionToken
		  }});
	  const command = new InferSNOMEDCTCommand({ Text: text });
	  const resp1 = await client.send(command);
	  //console.log("Got SnomedCT", resp1.Entities);
	  var entities1= resp1.Entities;
	  //console.log('entities1: ',JSON.stringify(entities1));
	  if (entities1.length === 0) return [];
	  if (entities1[0].Category==='TEST_TREATMENT_PROCEDURE')
		entity= entities1[0].Text

		if (entity === undefined || entity.replace(/\s/g, '') === '') return [];
		console.log("Calling CPTNorms for: ", entity);	  
		assistantId='asst_XCKF3lpKVoRklQvJmEcELqG3';
		norm='(CPT)';
		normConcept='SNOMEDCTConcepts';
		category='TEST_TREATMENT_PROCEDURE';
		type = "TEST_VALUE";
  } else if (entities[0].Category==='MEDICATION'){
	entity= entities[0].Text
	assistantId='asst_26RhVbAwc49XU8NBialPjTYf';
	norm='(DDC)';
	normConcept='DDCNormConcepts';
	category='MEDICATION';
	type = "BRAND_NAME";	
  }

	if (entity === undefined || entity.replace(/\s/g, '') === '') return [];
	//console.log("Calling DDCNorms for: ", entity);
	var threadRun= await createThreadAndRun(assistantId, entity);
	var retrieve= await retrieveRun(threadRun.thread_id, threadRun.id);
	var start = new Date();
	// Wait for the response to be ready
	while (retrieve.status === "in_progress" || retrieve.status === "queued") {
		  //console.log("waiting...", (new Date()));
		  await new Promise((resolve) => setTimeout(resolve, 5000));
		  retrieve= await retrieveRun(threadRun.thread_id, threadRun.id);
	}
	var end = new Date();
	var difference = (end - start) / 1000;

	console.log("You waited: " + difference + " seconds for : ", entity, norm);
	
	var messageList=await listMessages(threadRun.thread_id);
	// Find the last message for the current run
    const lastMessage = messageList.data.filter((message) => message.run_id === threadRun.id && message.role === "assistant")
      .pop();

	// Print the last message coming from the assistant
	if (lastMessage) {
		//console.log(lastMessage.content[0]["text"].value);	
		var content=lastMessage.content[0]["text"].value;
		content=content.replace('json','');
		content=content.replace(/`/g,'');
		try{
			//console.log('content for: ', entity, ':',content);
			//console.log("Got DDCNorms", JSON.parse(content));
			//contentList=JSON.parse(content);
			var s=content.indexOf('[');
			var e=content.indexOf(']')+1;
			content=content.substring(s,e);
			//console.log('content: ',content);
			if (content.replace(/\s/g, '') === '') return [];
			var jsonContent=JSON.parse(content);
			if (jsonContent.length === 0) return contentList;
			var fetchedEntities={
				"Id": Math.floor(Math.random()*10) + 1,
				"BeginOffset": 69,
				"EndOffset": 74+Math.floor(Math.random()*10),
				"Score": 0.99999351831436157,
				//"Text": entity+norm,
				"Text": entity,
				"Category": category,
				"Type": type,
				"Traits": [],
				"id": uuidv4(),
				"Attributes": [],
			}
			fetchedEntities[normConcept]= jsonContent;
			contentList.push(fetchedEntities);  
		}catch(e) {
			console.log('Error in content: ', entity, e);
		}
	}
	//console.log('contentList: ', JSON.stringify(contentList));
  return contentList;
};

async function createThreadAndRun(assistant_id, prompt){
	var returnVal='';
	var indata = {
		"assistant_id": assistant_id,
		"thread": {
	        "messages": [
	          {"role": "user", "content": prompt}
	        ]
	      }
		};
	var url=`${azureEndpoint}/openai/threads/runs?api-version=${apiVersion}`;
	await fetch(url, {
      method: 'POST',
	  headers: headers,
      body: JSON.stringify(indata),
    })
	.then((res) => res.json())
	.then((data) => {returnVal=data})
	.catch((err) => console.error(err));
	return returnVal;
}

async function retrieveRun(thread_id, run_id){
	var returnVal='';
	var url=`${azureEndpoint}/openai/threads/${thread_id}/runs/${run_id}?api-version=${apiVersion}`;
	await fetch(url, {
      method: 'GET',
	  headers: headers,
    })
	.then((res) => res.json())
	.then((data) => {returnVal=data})
	.catch((err) => console.error(err));
	return returnVal;
}

async function listMessages(thread_id){
	var returnVal='';
	var url=`${azureEndpoint}/openai/threads/${thread_id}/messages?api-version=${apiVersion}`;
	await fetch(url, {
      method: 'GET',
	  headers: headers,
    })
	.then((res) => res.json())
	.then((data) => {returnVal=data})
	.catch((err) => console.error(err));
	return returnVal;
}

export default detectEntities;
