import { ComprehendMedical } from 'aws-sdk';
import { Entity } from 'aws-sdk/clients/comprehendmedical';
import { ComprehendMedicalClient, InferSNOMEDCTCommand ,ComprehendMedicalClientConfig} from "@aws-sdk/client-comprehendmedical"; 
import getCredentials from '../audio-utils/getTranscribeCredentials';


const detectEntities = async (
  text: string | undefined,config
): Promise<Entity[]> => {
  //const comprehendMedical = new ComprehendMedical(clientParams);
  const client = new ComprehendMedicalClient({region: config.region,
  credentials: {
    accessKeyId: config.accessKeyId,
    secretAccessKey: config.secretAccessKey,
    sessionToken: config.sessionToken
  }});
  if (text === undefined || text.replace(/\s/g, '') === '') return [];

  //const resp = await comprehendMedical.InferSNOMEDCT({ Text: text }).promise();
  console.log("Calling SnomedCT");
  const command = new InferSNOMEDCTCommand({ Text: text });
  const resp = await client.send(command);
  console.log("Got SnomedCT", resp.Entities);
  return resp.Entities;
};

export default detectEntities;
