import { useState, useEffect } from 'react';

import { v4 as uuid } from 'uuid';

import detectEntities from './ai/detectEntities';
import inferICD10CM from './ai/inferICD10CM';
import inferRxNorm from './ai/inferRxNorm';
import inferSnoMed from './ai/inferSnoMed';
import inferDDCNorm from './ai/inferDDCNorm';
import { sortByScoreDescending } from './utils/conceptUtils';

import md5 from 'tiny-hashes/md5';

// This cache holds pre-recorded reponses for use in offline mode.
// Attached to window.__comprehend_cache so we can provide the copy button
// in the hidden debug menu
const cache = (window.__comprehend_cache = require('./recorded-responses/comprehension-cache.json'));

// This WeakMap links single-line transcript chunks to their responses without directly attaching as a property
const resultMap = new WeakMap();

//Map for questions
const questionOrderMap = new Map();
let lastQuestion = null;

// React hook to take an array of transcript chunks and return a corresponding array of Comprehend results, one for each
export default function useComprehension(transcriptChunks, clientParams) {
  const [result, setResult] = useState([]);

  useEffect(() => {
    const addResult = (chunk, entities, cacheKey) => {
	
      const prev = resultMap.get(chunk);
      let next = [...prev];

      entities.forEach((e) => {
        const matching = prev.find((x) => {
          return (
            x.BeginOffset === e.BeginOffset &&
            x.EndOffset === e.EndOffset &&
            x.Type === e.Type &&
            x.Category === e.Category
          );
        });

        // If there's already an entity with these exact properties, extend it.
        // Specifically, for ICD10CM and RxNorm, there's usually a pre-existing
        // entity returned by the general comprehend response, and we're attaching
        // the concepts to it.
        if (matching) {
          next = next.filter((y) => y !== matching);
          next.push({ ...matching, ...e });
        } else {
          e.id = uuid();
          next.push(e);
        }
      });
      
      //Remove Duplicate from next
      cache[cacheKey] = next;
      
      resultMap.set(chunk, next);
      setResult(transcriptChunks.map((chunk) => resultMap.get(chunk) ?? []));
    };

    for (const chunk of transcriptChunks) {
       const existing = resultMap.get(chunk);
      if (!existing ) {
	console.log("Current chunk:",chunk.text);
	if (lastQuestion != null && determineFinalOutcome(chunk.text) === 'No'){
	      console.log("Found No, Removing",resultMap.get(lastQuestion));
              let lastEntities = resultMap.get(lastQuestion);
              const filtered = lastEntities.filter(entity => !isRelevantEntity(entity, lastQuestion.text));
              resultMap.set(lastQuestion, filtered);
              setResult(transcriptChunks.map((lastQuestion) => resultMap.get(lastQuestion) ?? []));
	      resultMap.set(chunk, filtered);
              lastQuestion = null;
      }else{
        resultMap.set(chunk, []);
         console.log("Current chunk in else:",chunk.text);
        const cacheKey = md5(chunk.text);

        // Only use the cached version if the offline flag is set
        if (cache[cacheKey] && useComprehension.__offline) {
          addResult(chunk, cache[cacheKey], cacheKey);
          return;
        }

        detectEntities(chunk.text, clientParams).then((entities) => {
          addResult(chunk, entities, cacheKey);
        });
		
        inferDDCNorm(chunk.text, clientParams).then((rawEntities) => {
	  console.log("rawEntities ddcnorm", rawEntities);
	  var normConcept='DDCNormConcepts';
	  if (rawEntities.length>0 && rawEntities[0].Category==='TEST_TREATMENT_PROCEDURE')
		normConcept='SNOMEDCTConcepts';
          const entities = addSelectedConceptCodeAndSortConcepts(rawEntities, normConcept);
          addResult(chunk, entities, cacheKey);
        });		
        inferRxNorm(chunk.text, clientParams).then((rawEntities) => {
	  console.log("rawEntities rxnorm", rawEntities);
          const entities = addSelectedConceptCodeAndSortConcepts(rawEntities, 'RxNormConcepts');
          addResult(chunk, entities, cacheKey);
        });

        inferSnoMed(chunk.text, clientParams).then((rawEntities) => {
	  console.log("rawEntities snomed", rawEntities);
	 //const entities = addSelectedConceptCodeAndSortConcepts(rawEntities, 'ICD10CMConcepts');
          const entities = addSelectedConceptCodeAndSortConcepts(rawEntities, 'SNOMEDCTConcepts');
	addResult(chunk, entities, cacheKey);
        });

	 inferICD10CM(chunk.text, clientParams).then((rawEntities) => {
          console.log("rawEntities icd10cm", rawEntities);
         const entities = addSelectedConceptCodeAndSortConcepts(rawEntities, 'ICD10CMConcepts');
         //Set DIAGNOSIS Codes
	 entities.forEach((entity) => {
            if (entity.Category === 'MEDICAL_CONDITION') {
                // Check if the entity has the DIAGNOSIS trait
                const hasDiagnosisTrait = entity.Traits.some(trait => trait.Name === 'DIAGNOSIS');
                if (hasDiagnosisTrait) {
                    // If it's a diagnosis, add to diagnosisEntities list
                    entity.Category = 'DIAGNOSIS';
            }
	    }});
         addResult(chunk, entities, cacheKey);
        });
	 /*
	      //Filter last identified entities if it was a question and it is negatively responded
      console.log("Current chunk:",chunk.text);
      if (lastQuestion != null && determineFinalOutcome(chunk.text) === 'No'){
              let lastEntities = resultMap.get(lastQuestion);
              const filtered = lastEntities.filter(entity => !isRelevantEntity(entity, lastQuestion.text));
              resultMap.set(lastQuestion, filtered);
	      setResult(transcriptChunks.map((lastQuestion) => resultMap.get(lastQuestion) ?? []));
	      console.log("Entities Before:", resultMap.get(chunk));
              const entities = resultMap.get(chunk).filter(entity => !isRelevantEntity(entity, chunk.text));
	      console.log("entities::",entities);
	      resultMap.set(chunk, entities);
	      setResult(transcriptChunks.map((chunk) => resultMap.get(chunk) ?? []));
	      lastQuestion = null;
      }*/
	       if(isQuestion(chunk.text)){
              lastQuestion = chunk;
      }
      }
      }
    }
  }, [transcriptChunks, clientParams]);

  return [result, setResult];
}

const addSelectedConceptCodeAndSortConcepts = (rawEntities, conceptAttribute) =>
  rawEntities.map((entity) => {
    if (entity[conceptAttribute] === null || entity[conceptAttribute].length === 0) return entity;

    const sortedConcepts = sortByScoreDescending(entity[conceptAttribute]);

    return { ...entity, [conceptAttribute]: sortedConcepts, selectedConceptCode: sortedConcepts[0].Code };
  });

// Function to analyze sentences from the Map and filter entities based on "No" and "Yes"
function processMedicalEntitiesFromMap(sentenceMap) {
    let identifiedEntities = [];  // To store final identified entities
    let previousEntities = [];    // Track entities from the last sentence

    // Loop through each sentence in the map
    for (const [sentence, entities] of sentenceMap) {
        const lowerCaseSentence = sentence.toLowerCase();

        if (lowerCaseSentence.includes('no')) {
            if (lowerCaseSentence.includes('yes')) {
                // If sentence contains both 'No' and 'Yes', retain the entities
                console.log(`Sentence contains both 'No' and 'Yes', retaining entities from: "${sentence}"`);
                identifiedEntities.push(...previousEntities);
            } else {
                // If sentence contains 'No', discard previously identified entities
                console.log(`'No' found, removing entities from: "${sentence}"`);
                previousEntities = [];  // Clear previous entities
            }
        } else if (lowerCaseSentence.includes('yes')) {
            // If sentence contains 'Yes', retain the previous entities
            console.log(`'Yes' found, retaining entities from: "${sentence}"`);
            previousEntities = entities;
            identifiedEntities.push(...previousEntities);
        } else {
            // If no 'No' or 'Yes', keep adding the entities
            previousEntities = entities;
            identifiedEntities.push(...previousEntities);
        }
    }

    // Remove duplicates from the final list of entities
    const finalEntities = [...new Set(identifiedEntities)];  // Use Set to filter duplicates

    return finalEntities;
}

// Function to extract final outcome of yes/no from patient response
function determineFinalOutcome(response) {
  const lowerCasedResponse = response.toLowerCase();

  const hasYes = lowerCasedResponse.includes('yes');
  const hasNo = lowerCasedResponse.includes('no');

  if (hasNo && !hasYes) {
    return "No";
  } else if (hasNo && hasYes) {
    const firstNoIndex = lowerCasedResponse.indexOf('no');
    const firstYesIndex = lowerCasedResponse.indexOf('yes');
    return firstYesIndex > firstNoIndex ? "Yes" : "No";
  }
  return hasYes ? "Yes" : "No";
}

// Function to detect whether a sentence is a question
function isQuestion(sentence) {
  const questionStarters = ['do', 'did', 'is', 'are', 'have', 'has', 'was', 'were', 'can', 'will'];
  const trimmedSentence = sentence.toLowerCase();

  // Basic rule: If a sentence starts with question words, assume it's a question
  return questionStarters.some(start => trimmedSentence.startsWith(start));
}

// Function to find the last question in the WeakMap (for simplicity, let's say it's the last key)
function findLastQuestion(weakMap) {
    // Get the last inserted key from the WeakMap
    //for (let question of weakMap.keys()) {
      //  return question && question.text && isQuestion(question.text) ? question: null;  // Assuming this is the last question (you can refine this logic)
    //}
    return lastQuestion ? lastQuestion : null;
}

// Function to determine if an entity is relevant to the question
function isRelevantEntity(entity, question) {
    return question.toLowerCase().includes(entity.Text.toLowerCase());
}

// Function to filter duplicates in entities by category and type, retaining the highest score
function filterEntitiesByCategoryAndType(resultMap) {
    // Iterate through the WeakMap
    for (let [key, entities] of resultMap) {
        const filteredEntitiesMap = new Map();  // To store unique (category, type) entities

        // Iterate over the entities for each entry in the WeakMap
        entities.forEach(entity => {
            const { Category, Type, Text, Score } = entity;
            const key = `${Category}_${Type}_${Text}`;  // Create a composite key based on category and type

            // If this category+type already exists, compare the scores
            if (filteredEntitiesMap.has(key)) {
                const existingEntity = filteredEntitiesMap.get(key);
                // Keep the entity with the higher score
                if (Score > existingEntity.Score) {
                    filteredEntitiesMap.set(key, entity);
                }
            } else {
                // If no entity with this category+type exists, add it
                filteredEntitiesMap.set(key, entity);
            }
        });

        // Convert filteredEntitiesMap back to an array and update the resultMap with filtered entities
        resultMap.set(key, Array.from(filteredEntitiesMap.values()));
    }
}

